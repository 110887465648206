.navbar {
	text-align: center;
	margin: 2rem 2rem 4rem 2rem;
}

.navbar h1 {
	color: var(--accent-color);
	margin-bottom: 0;
}

h1 {
	font-family: 'Anton';
	letter-spacing: 0.1rem;
}

.navbar p {
	margin: 0.25rem 0 2rem 0;
	color: #9b9b9b;
	font-size: 18px;
}

:root {
	--main-bg-color: #f1f3f4;
	--light-gray: #d1d1d1;
	--dark-gray: #abacae;
	--accent-color: #ff5656;
}

body {
	background-color: var(--main-bg-color);
	width: 100%;
	min-height: 100vh;
}

.content-container {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-evenly;
	align-items: flex-start;
	width: 100%;
	height: 100%;
	max-width: 1920px;
}

.content-module {
	display: flex;
	flex-flow: row wrap;
	flex-direction: column;
	justify-content: space-evenly;
	background-color: #ffffff;
	border-bottom: 4px solid var(--accent-color);
	margin-bottom: 2rem;
	width: 40%;
	box-shadow: 2px 2px 5px var(--light-gray);
	max-width: 500px;
}

.content-module h1 {
	text-align: center;
	margin-bottom: 0;
	font-weight: 400;
	color: var(--dark-gray);
}

.content-module ul {
	padding: 0;
	list-style: none;
	width: 100%;
}

.content-module li {
	list-style: none;
	margin: 1rem 1rem 2rem 1rem;
	transition: 100ms all ease-in-out;
	border-bottom: 1px solid var(--dark-gray);
	display: flex;
	justify-content: space-evenly;
	align-items: flex-start;
}

.content-module li:hover {
	cursor: pointer;
}

.content-module li:hover .icon {
	transform: scale(0.9);
}

.content-module li .icon {
	opacity: 0.25;
	margin: 0.25rem 1rem 0 0;

	transition: 100ms all ease-in-out;
}

.listing-group {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	width: 90%;
	overflow-wrap: break-word;
	word-wrap: break-word;

	-ms-word-break: break-all;
	/* This is the dangerous one in WebKit, as it breaks things wherever */
	word-break: break-all;
	/* Instead use this non-standard one: */
	word-break: break-word;

	/* Adds a hyphen where the word breaks, if supported (No Blink) */
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

.listing-group h3 {
	margin: 0;
	margin-right: 1rem;
}

.listing-group p {
	margin: 0.25rem 0 2rem 0;
}

.listing-group .item-url {
	color: var(--dark-gray);
	margin: 0;
}

#tags {
	margin-top: 0.5rem;
	width: 6rem;
	height: 2rem;
}

@media (max-width: 600px) {
	.content-container {
		flex-direction: column;
		align-items: center;
	}
	.content-module {
		width: 90%;
	}
	.content-module li {
		flex-direction: column;
		align-items: center;
	}
	.content-module ul .icon {
		margin-bottom: 1rem;
	}
}
